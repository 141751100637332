<template>
  <i18n-t :keypath="keypath" tag="p" class="text" data-t="body-message">
    <template #bonus>
      {{ bonusText }}
    </template>
    <template #amount>
      <b>{{ notificationAmount }}</b>
    </template>
    <template #currencyCode>
      <b>{{ notification.data.currencyCode }}</b>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import type { BonusNotification } from '../../../../types'

const props = defineProps<{
  notification: BonusNotification
}>()

const { notification } = toRefs(props)
const { notificationAmount } = useCurrencyNotification(notification)
const { bonusType } = useBonusNotification(notification)

const keypath = computed(() => {
  if (!bonusType.value) return ''

  if (bonusType.value === 'bonusForDeposit') {
    return notification.value.type === 'bonusCreated'
      ? 'notifications.body.bonusForDepositCreated'
      : 'notifications.body.bonusForDepositExpired'
  }

  return notification.value.type === 'bonusCreated'
    ? 'notifications.body.bonusCreated'
    : 'notifications.body.bonusExpired'
})

const { t } = useI18n()
const bonusText = computed(() => {
  if (!bonusType.value) return ''
  return (
    {
      ratRace:
        notification.value.type === 'bonusCreated'
          ? t('notifications.bonuses.ratRace')
          : t('notifications.bonuses.ratRaceExpired'),
      cashback:
        notification.value.type === 'bonusCreated'
          ? t('notifications.bonuses.cashback')
          : t('notifications.bonuses.cashbackExpired'),
      rakeBack:
        notification.value.type === 'bonusCreated'
          ? t('notifications.bonuses.rakeback')
          : t('notifications.bonuses.rakebackExpired'),
      reload:
        notification.value.type === 'bonusCreated'
          ? t('notifications.bonuses.reload')
          : t('notifications.bonuses.reloadExpired'),
      quest:
        notification.value.type === 'bonusCreated'
          ? t('notifications.bonuses.quest')
          : t('notifications.bonuses.questExpired'),
      bonusForDeposit: '',
    }[bonusType.value] ?? ''
  )
})
</script>

<style scoped>
.text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);

  b {
    font: var(--desktop-text-xs-semibold);
    color: var(--text-primary);
  }
}
</style>
